// TOUCH OR NO-TOUCH, THAT IS THE QUESTION!
// SCROLL-TO ANIMATION FOR ANCHOR LINKS
// FADE-IN-UP ELEMENTS ON SCROLL
// SLIDER (SWIPER JS)
// SLIDER (SLICK JS)
// PRODUCT PICKER
// NEWSLETTER PICKER



//
// =TOUCH OR NO-TOUCH, THAT IS THE QUESTION!
// --------------------------------------------------

// function isTouchDevice() {
//   return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
// }
// if (isTouchDevice() === true) {
//   $("body").toggleClass("touch");
// } else {
//   $("body").toggleClass("no-touch");
// }


//
// =SCROLL-TO ANIMATION FOR ANCHOR LINKS
// --------------------------------------------------

// $('.msdw-page-scroll').click(function() {
//   if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
//     var target = $(this.hash);
//     target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
//     if (target.length) {
//       var offsetValue = 0;
//       if ( window.innerWidth <= 767 ) {
//         offsetValue;
//         // console.log(offsetValue);
//       } else {
//         offsetValue = 59;
//         // console.log(offsetValue);
//       }

//       $('html,body').animate({
//         scrollTop: target.offset().top - offsetValue
//       }, 900);
//       return false;
//     }
//   }
// });


//
// =FADE-IN-UP ELEMENTS ON SCROLL
// --------------------------------------------------

// *** IntersectionObserver ***

// const elements = document.querySelectorAll(".msdw-animate");

// function check(entries) {
//   entries.map((entry) => {
//     if (entry.isIntersecting) {
//       entry.target.classList.add("msdw-animated");
//       observer.unobserve(entry.target);
//     }
//   });
// }

// const observer = new IntersectionObserver(check);

// elements.forEach((elem) => observer.observe(elem));


// *** requestAnimationFrame ***

// const scroll = window.requestAnimationFrame;

// function showOnScroll() {
//   const elems = document.querySelectorAll('.msdw-animate');

//   elems.forEach((el) => {
//     const elPosition = el.getBoundingClientRect().top
//     const elScreen = window.innerHeight / .9

//     if (elPosition < elScreen) {
//       el.classList.add('msdw-animated')
//     }
//   });

//   scroll(showOnScroll);
// }

// showOnScroll();


//
// =SLIDER (SWIPER JS)
// --------------------------------------------------

// var swiper = new Swiper('.swiper-container', {
//   slidesPerView: 1,
//   loop: true,
//   navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//   },
// });


//
// =SLIDER (SLICK JS)
// --------------------------------------------------

// $('.msdw-slider-jacket').slick({
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   swipe: false,
//   arrows: false,
//   swipeToSlide: true,   // in case, 2 or more slides at a time
//   autoplay: true,       // if autoplay...
//   accessibility: false  // use this to avoid page jumping bug
// });

// $('.slick-prev').click(function() {
//   $('.msdw-slider-jacket').slick('slickPrev');
// });
//
// $('.slick-next').click(function() {
//   $('.msdw-slider-jacket').slick('slickNext');
// });

// UNSLICK
// If needed, here's a fix for window resizing
// $(window).on('orientationchange resize', function() {
//   $('.msdw-slider-jacket').slick('resize');
// });


//
// =PRODUCT PICKER
// --------------------------------------------------

// const productPickers = document.querySelectorAll('.msdw-product-detail-picker');

// productPickers.forEach(productPicker => {
//   const productIdNumber = productPicker.dataset.productId;
//   productPicker.addEventListener('click', (e) => {
//     e.preventDefault();
//     window.jwsdwMediator.publish('openPicker', 'productDetailPicker', {
//       'productId': productIdNumber
//     });
//   });
// });


//
// =NEWSLETTER PICKER
// --------------------------------------------------

// var campaignId = 'familyAndFriends',
//     pickerTag = document.getElementById("openPicker"),
//     newsletterConfig = {},
//     baseUrl = jwsdwSettings.baseUrl;

// $.ajax({
//   type: 'GET',
//   url: baseUrl + '/Newsletter-getNewsletterConfig',
//   data: {
//     campaignId: campaignId
//   },
//   success: function(response) {
//     newsletterConfig = response
//   },
//   error: function(response) {
//     jwsdwApi.popup.showHttpError(response.status)
//   }
// });

// pickerTag.addEventListener("click", function(){
//   window.jwsdwMediator.publish('openPicker', 'newsletterSignupPicker', {
//     "campaign": newsletterConfig.id,
//     "introduction": newsletterConfig.introduction,
//     "disclaimerOne": newsletterConfig.disclaimerOne,
//     "disclaimerTwo": newsletterConfig.disclaimerTwo
//   });
// });

const stickyTop = document.querySelector('.msdw-sticky-bar-top');
const navi = document.querySelector('.jwsdw-header-container header');
const stickyDisplay = document.getElementById('msdw-barDisplay');
const closeContentBar = document.querySelector('.msdw-contentBar-close');

window.addEventListener('DOMContentLoaded', () => {
    if (navi) {
        navi.before(stickyTop);
    }
});

window.addEventListener('DOMContentLoaded', () => {
    if (stickyDisplay.style.display = "none") {
        stickyDisplay.style.display = "block";
    }
});

// Close content bar
closeContentBar.addEventListener('click', () => {
    if (stickyDisplay.style.display = "block") {
        stickyDisplay.style.display = "none"
    }
})



CountDownTimer('02/20/2025 0:00 AM');

function CountDownTimer(dt) {
    var end = new Date(dt);

    // Subtract 1 hour for UK time
    const classList = document.documentElement.classList; 
    if (classList.contains('jwsdw-countryCode-GB')) {
        end.setHours(end.getHours() + 1);
    }

    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var timer;

    function showRemaining() {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {
            clearInterval(timer);
            return;
        }
        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);

        document.querySelector('.msdw-countdown-days span').innerHTML = days;
        document.querySelector('.msdw-countdown-hours span').innerHTML = hours;
        document.querySelector('.msdw-countdown-minutes span').innerHTML = minutes;
        // document.querySelector('.msdw-countdown-seconds span').innerHTML = seconds;
    }

    timer = setInterval(showRemaining, 1000);
}
